<template>
  <section id="dashboard-ecommerce">
    <b-overlay
      id="overlay-background"
      :show="show"
      variant="primary"
      opacity="0.85"
      blur="2"
      rounded="sm"
    >
      <div>
        <b-card no-body class="mb-0">
          <b-table
            :striped="true"
            :bordered="true"
            :hover="true"
            ref="refUserListTable"
            class="position-relative"
            :items="orders"
            responsive
            :fields="tableColumns"
            primary-key="id"
            sort-by.sync="id"
            show-empty
            empty-text="Kayıt bulunamadı."
            head-variant="dark"
          >
            <template #cell(ad)="data">
              <div class="item-quantity d-flex justify-content-center">
                {{ data.item.ad }}
              </div>
            </template>
            <template #cell(kod)="data">
              <div class="item-quantity d-flex justify-content-center">
                {{ data.item.kod }}
              </div>
            </template>
            <template #cell(addressCode)="data">
              <div class="item-quantity d-flex justify-content-center">
                {{ data.item.addressCode }}
              </div>
            </template>
            <template #cell(productCode)="data">
              <div class="item-quantity d-flex justify-content-center">
                {{ data.item.productCode }}
              </div>
            </template>
            <template #cell(balanceFormatted)="data">
              <div class="item-quantity d-flex justify-content-end">
                {{ data.item.balanceFormatted }}
              </div>
            </template>
            <template #cell(unitType)="data">
              <div class="item-quantity d-flex justify-content-end">
                {{ data.item.unitType }}
              </div>
            </template>
            <template #cell(date)="data">
              <div class="item-quantity d-flex justify-content-end">
                {{ format(new Date(data.item.date)) }}
              </div>
            </template>
            <template #cell(id)="data">
              <div class="item-quantity d-flex justify-content-end">
                {{ data.item.id }}
              </div>
            </template>
          </b-table>
          <div class="mx-3 mb-3">
            <b-row>
              <b-col
                cols="4"
                sm="4"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Toplam sipariş sayısı:<strong> {{ ordersCount }}</strong>
                </span>
              </b-col>

              <b-col
                cols="4"
                sm="4"
                class="d-flex align-items-center justify-content-center justify-content-sm-center"
              >
                <download-excel
                  :data="allOrders"
                  type="xls"
                  name="Bakiyelerim.xls"
                  :fields="json_fields"
                  worksheet="Bakiyelerim"
                >
                  <b-button
                    style="margin-top: 0px"
                    type="button"
                    variant="primary"
                  >
                    <feather-icon icon="DownloadCloudIcon" class="mr-50" />
                    <span class="align-middle">Excel Olarak İndir</span>
                  </b-button>
                </download-excel>
              </b-col>

              <b-col
                cols="4"
                sm="4"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="ordersCount"
                  per-page="15"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                  @input="changePage(currentPage)"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
    </b-overlay>
  </section>
</template>

<script>
import {
  BFormInput,
  BOverlay,
  BModal,
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BButton,
  BCol,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BImg,
  BFormSpinbutton,
  BDropdownItem,
  BPagination
} from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  components: {
    BRow,
    BImg,
    BCol,
    BCard,
    BModal,
    BOverlay,
    BFormInput,
    BCardHeader,
    BCardBody,
    BButton,
    BFormSpinbutton,
    vSelect,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination
  },
  data() {
    return {
      orders: [],
      allOrders: [],
      tableColumns: [
        { key: "ad", label: "AD" },
        { key: "addressCode", label: "ADRES KODU" },
        { key: "productCode", label: "STOK KODU" },
        { key: "balanceFormatted", label: "BAKIYE" },
        { key: "unitType", label: "BIRIM" },
        { key: "date", label: "TARIH" },
        { key: "id", label: "SIPARIŞ NO" }
      ],
      ordersCount: 0,
      currentPage: 1,
      show: false,
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ],
      json_fields: {
        AD: "ad",
        Adres: "addressCode",
        "Stok Kodu": "productCode",
        Bakiye: "balanceFormatted",
        Birim: "unitType",
        Tarih: {
          field: "date",
          callback: value => {
            return this.format(new Date(value));
          }
        },
        SiparişNo: "id"
      }
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.show = true;
      this.$http
        .get("/User/GetERPOrderBalance")
        .then(response => {
          this.allOrders = response.data.result;
          this.orders = this.allOrders.slice(0, 15);
          this.ordersCount = this.allOrders.length;
          this.show = false;
        })
        .catch(error => {
          this.show = false;

          this.$swal({
            title: "Hata!",
            text: error.response.data.responseException.exceptionMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false
          });
        });
    },
    changePage(cPage) {
      const skip = (cPage - 1) * 15;
      const take = skip + 15;
      console.log(skip + "," + take);
      this.orders = this.allOrders.slice(skip, take);
    },
    format(date) {
      var month = date.toLocaleString("tr-TR", { month: "short" });
      return date.getDate() + " " + month + " " + date.getFullYear();
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
</style>
